// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "z_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "z_fR d_fR d_bz d_bJ";
export var menuDesign6 = "z_t3 d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "z_t4 d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "z_t5 d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "z_t6 d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "z_t7 d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "z_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "z_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "z_t8";
export var navbarItem = "z_nb d_bx";
export var navbarLogoItemWrapper = "z_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "z_t9 d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "z_vb d_gd d_by d_Z d_bs";
export var burgerToggle = "z_vc d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "z_vd d_gd d_by d_Z d_bs";
export var burgerInput = "z_vf d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "z_vg d_f3 d_w d_H";
export var burgerLine = "z_vh d_f1";
export var burgerMenuDesign6 = "z_vj d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "z_vk d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "z_vl d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "z_vm d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "z_vn d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "z_vp d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "z_vq d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "z_vr d_bC d_bP";
export var compact = "z_vs";
export var navDivided = "z_vt";
export var staticBurger = "z_vv";
export var menu = "z_vw";
export var navbarDividedLogo = "z_vx";
export var nav = "z_vy";
export var fixed = "z_vz";